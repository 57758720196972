export default {

	init() {
		const accordions = document.getElementsByClassName("accordion");
		if (!accordions) {
			return;
		}

		// jumpmark
		const accordionTabId = window.location.hash.slice(1);
		const activeTab = document.getElementById(accordionTabId);

		if (activeTab) {
			activeTab.classList.add("active");

			const answerBlockActive = activeTab.querySelector(".answer-faq");
			if (answerBlockActive) {
				answerBlockActive.style.maxHeight = answerBlockActive.scrollHeight + "px";
			}
		}



		accordions.forEach(function (accordion) {



			const faqBlocks = accordion.querySelectorAll(".faq-block");

			if (faqBlocks.length) {
				faqBlocks.forEach(faqBlock => {
					faqBlock.addEventListener("click", function () {
						const activeFaqBlock = accordion.querySelector(".faq-block.active");

						if (activeFaqBlock && activeFaqBlock != faqBlock) {
							activeFaqBlock.classList.remove("active");
							activeFaqBlock.querySelector(".answer-faq").style.maxHeight = null;
						}

						const answerBlock = faqBlock.querySelector(".answer-faq");

						if (faqBlock.classList.contains("active")) {
							faqBlock.classList.remove("active");
							answerBlock.style.maxHeight = null;
						} else {
							faqBlock.classList.add("active");
							answerBlock.style.maxHeight = answerBlock.scrollHeight + "px";
						}


					})
				});
			}
		})

	},

};