export default {
	init() {

		const inputField = document.getElementById("input-search-field");
		const resetButton = document.getElementById("reset-button");

		if (!inputField || !resetButton) {
			return;
		}

		function clearFields() {
			inputField.value = "";
		}

		if (inputField.value == "") {
			resetButton.classList.add("hidden");
		}

		if (inputField) {
			inputField.addEventListener("keypress", function () {
				resetButton.classList.remove("hidden");
			})
		}

		if (resetButton) {
			resetButton.addEventListener("click", clearFields);
		}

		if (resetButton) {
			resetButton.addEventListener("click", function () {
				resetButton.classList.add("hidden");
			});
		}
	},
}
