export default {

	init() {
        function replaceNumericWithAlphabetical(numbers) {
            const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            let result = "";
            numbers.forEach((num) => {
              const index = parseInt(num, 10) - 1; // convert num to zero-based index
              result += alphabet[index] + " ";
            });
            return result.trim();
        }

		const contest = document.querySelector(".contest");
		if (!contest) {
			return;
		}

        let stepCount = 1;
        const answerQuestionBtn = contest.querySelector(".js-answer-question");
        const submitQuestionBtn = contest.querySelector(".js-submit-question");
        const questionSteps = contest.querySelectorAll(".step");
        const contestButtonsWrapper = contest.querySelector(".contest-buttons");
        const answeredQuestions = [];
        let chosenAnswer = 0;

        if(!answerQuestionBtn) {
            return;
        }
        
        const rightAnswersWrapper = document.querySelector(".data-ara");
        
        if(!rightAnswersWrapper) {
            return;
        }
        
        const rightAnswers = rightAnswersWrapper.dataset.ara.split(",");
        
        if(!rightAnswers) {
            return;
        }
        
        submitQuestionBtn.addEventListener("click", () => {
            stepCount++;
            
            const activeStep = contest.querySelector(".step.active");
            const nextStep = contest.querySelector(".step-" + stepCount);
            
            if(activeStep) {
                contest.querySelector(".step.active").classList.remove("active");
            }
            if(nextStep) {
                nextStep.classList.add("active");
            }

            answeredQuestions.push(chosenAnswer);

            // Reset 
            chosenAnswer = 0;
            const successMessage = contest.querySelector(".quiz-message .success");
            const wrongMessage = contest.querySelector(".quiz-message .wrong");

            if(successMessage && wrongMessage) {
                successMessage.classList.add("hidden");
                wrongMessage.classList.add("hidden");
            }

            //If last screen, hide contest buttons
            if(stepCount == questionSteps.length && contestButtonsWrapper) {
                contestButtonsWrapper.classList.add("hidden");
            }

            answerQuestionBtn.classList.remove("hidden");
            submitQuestionBtn.classList.add("hidden");

            const userAnswersField = contest.querySelector(".contest-form .custom-data input");

            // Set the user answers in the hidden input field
            if(userAnswersField) {
                userAnswersField.value = replaceNumericWithAlphabetical(answeredQuestions);
            }
        })
        
        if(!questionSteps.length) {
            return;
        }
        
        questionSteps.forEach((questionStep) => {    
            const answers = questionStep.querySelectorAll(".answer-button");
            
            if(!answers.length) {
                return;
            }
            
            answers.forEach((answer, index) => {
                answer.addEventListener("click", () => {
                    
                    if(answer.classList.contains("logged")) {
                        answer.classList.remove("logged");
                        chosenAnswer = 0;
                        answerQuestionBtn.disabled = true;
                    } else {
                        const loggedAnswer = contest.querySelector(".answer-button.logged");
        
                        if(loggedAnswer) {
                            loggedAnswer.classList.remove("logged");
                        }
        
                        chosenAnswer = index + 1;
                        answer.classList.add("logged");
                        answerQuestionBtn.disabled = false;
                    }
                })
            });
        })
    
        answerQuestionBtn.addEventListener("click", () => {
            const loggedAnswer = contest.querySelector(".answer-button.logged");
            const activeStep = contest.querySelector(".step-" + stepCount);
            
            if(!activeStep) {
                return;
            }
            const currentLockScreen = activeStep.querySelector(".lock-screen");
            const currentAnswers = activeStep.querySelectorAll(".answer-button");

            if(!loggedAnswer || !currentAnswers.length || !currentLockScreen) {
                return;
            }

            currentLockScreen.classList.remove("hidden");

            // The answer that the user has chosen == the right answer in the current step
            if(chosenAnswer == rightAnswers[stepCount - 1]) {
                // Right Answer
                const successMessage = contest.querySelector(".quiz-message .success");
                loggedAnswer.classList.add("success");

                if(successMessage) {
                    successMessage.classList.remove("hidden");
                }
            } else {
                // Wrong Answer
                const wrongMessage = contest.querySelector(".quiz-message .wrong");

                const rightAnswerBtn = currentAnswers[(rightAnswers[stepCount - 1] - 1)];
                
                loggedAnswer.classList.add("wrong");

                if(rightAnswerBtn) {
                    rightAnswerBtn.classList.add("success");
                }

                if(wrongMessage) {
                    wrongMessage.classList.remove("hidden");
                }

            }

            currentAnswers.forEach((currentAnswer) => {
                currentAnswer.disabled = true;
            })

            answerQuestionBtn.classList.add("hidden");
            answerQuestionBtn.disabled = true;
            submitQuestionBtn.classList.remove("hidden");
        });

        let formieForm = document.querySelector("#contest-form form");

        formieForm.addEventListener("onAfterFormieSubmit", () => {
            const formSuccessScreen = contest.querySelector(".contest-form-success");

            if(formSuccessScreen) {
                formSuccessScreen.classList.remove("hidden");
            }
        });
	},

};