export default {

	init() {

		const accordionTabs = document.getElementsByClassName("accordion-tabs");

		if (!accordionTabs) {
			return;
		}

		accordionTabs.forEach(function (accordionTab) {

			const clickDiv = accordionTab.getElementsByClassName("click-div");

			clickDiv.forEach(function (icon) {

				icon.addEventListener("click", function (event) {

					const targetIndex = event.currentTarget.dataset.targetIndex;
					if (!targetIndex) {
						return;
					}

					const currentTitle = accordionTab.querySelector("[data-target-index='" + targetIndex + "']");
					const currenttabIcon = currentTitle;
					const allTitles = accordionTab.getElementsByClassName("click-div");

					allTitles.forEach(function (title) {
						title.classList.remove("active");
					})
					currenttabIcon.classList.add("active");

					const currentTabContent = accordionTab.querySelector("[data-content-index='" + targetIndex + "']");

					const allContentBlocks = accordionTab.querySelectorAll(".accordion-tab-content");
					allContentBlocks.forEach(function (content) {
						content.classList.add("hidden");
					})
					currentTabContent.classList.remove("hidden");
				})
			});


			if (clickDiv.length > 0) {
				clickDiv[0].click();
			}
		});
	},
};