export default {
	init() {

		const forms = document.querySelectorAll(".form-div");
        if (!forms) return;
		
        forms.forEach((form) => {
            const formId = form.dataset.formId;
            if (!formId) return;

            const currentForm = document.querySelector("#" + formId);
            if (!currentForm) return;

            const formHandle = currentForm.querySelector(
                "input[name='handle']"
            );
            //Find the CSRF token hidden input, so we can replace it
            const csrfInput = currentForm.querySelector(
				"input[name='CRAFT_CSRF_TOKEN']"
			);
				
            if (!formHandle || !csrfInput) return;

            // Fetch the new token for the form and replace the CSRF input with our new one
            fetch(
                "/actions/formie/forms/refresh-tokens?form=" + formHandle.value
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    csrfInput.outerHTML = result.csrf.input;
                });
        });



		const form = document.querySelector(".fui-form");

		if (form) {
			////// Input
			const input = form.querySelectorAll("input[type='text']");

			input.forEach((input) => {
				let inputParent = input.parentElement.previousElementSibling;

				input.addEventListener("focus", () => {
					inputParent.classList.add("float");
					inputParent.classList.add("selected");
					input.classList.add("selected");
				});

				input.addEventListener("blur", () => {
					if (input.value.length == 0) {
						inputParent.classList.remove("float");
					}
					inputParent.classList.remove("selected");
					input.classList.remove("selected");
				});
			})

			////// Mail
			const mail = form.querySelectorAll("input[type='email']");

			mail.forEach((mail) => {
				let mailParent = mail.parentElement.previousElementSibling;

				mail.addEventListener("focus", () => {
					mailParent.classList.add("float");
					mailParent.classList.add("selected");
					mail.classList.add("selected");
				});

				mail.addEventListener("blur", () => {
					if (mail.value.length == 0) {
						mailParent.classList.remove("float");
					}
					mailParent.classList.remove("selected");
					mail.classList.remove("selected");
				});
			})

			////// Textarea
			const textarea = form.querySelectorAll("textarea");

			textarea.forEach((textarea => {
				let textareaParent = textarea.parentElement.previousElementSibling;

				textarea.addEventListener("focus", () => {
					textareaParent.classList.add("float");
					textareaParent.classList.add("selected");
					textarea.classList.add("selected");
				});

				textarea.addEventListener("blur", () => {
					if (textarea.value.length == 0) {
						textareaParent.classList.remove("float");
					}
					textareaParent.classList.remove("selected");
					textarea.classList.remove("selected");
				});
			}))

			////// Dropdown
			const dropdown = form.querySelectorAll("select");

			dropdown.forEach((input) => {
				let inputParent = input.parentElement.previousElementSibling;

				input.addEventListener("change", () => {
					inputParent.classList.add("float");
				});
			})
		}
	},
}