import { enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import anime from "animejs";

export default {
	init() {

		//////////// Navigation
		const menu = document.getElementById("menu");
		const hamburger = document.getElementById("hamburger");
		const header = document.querySelector(".header-menu");
		const body = document.querySelector("body");
		const menuItems = document.querySelectorAll(".menu-item");
		const logo = document.querySelector(".logo");
		const languageListItemMobile = document.querySelector(".language-list-item");

		// We listen to the resize event
		window.addEventListener("resize", () => {
			if (menu.classList.contains("open")) {
				menu.classList.remove("open");
				hamburger.classList.remove("close");
				header.classList.remove("open-menu");
				body.classList.remove("overflow-visible");
				body.classList.remove("fixed");
				body.classList.remove("left-0");
				body.classList.remove("right-0");
			}
		});

		if (hamburger) {
			hamburger.addEventListener("click", function () {

				const timeline = anime.timeline({ duration: 400, easing: "easeOutQuint" });

				// close menu
				if (menu.classList.contains("open")) {
					menu.classList.remove("open");
					hamburger.classList.remove("close");
					header.classList.remove("open-menu");
					body.classList.remove("overflow-visible");
					body.classList.remove("fixed");
					body.classList.remove("left-0");
					body.classList.remove("right-0");

					if (languageListItemMobile) {
						languageListItemMobile.classList.add("open-menu-only");
					}
					// hide menu-items when menu closed
					menuItems.forEach(menuItem => {
						if (menuItem.dataset.lightswitchClosed == true) {
							menuItem.classList.add("open-menu-only");
						}
					});
					// show menu-items when menu closed
					menuItems.forEach(menuItem => {
						if (menuItem.dataset.lightswitchOpen == true) {
							menuItem.classList.remove("closed-menu-only");
						}
					});
					logo.classList.remove("hidden");
					logo.classList.add("block");

					if (languageListItemMobile) {
						languageListItemMobile.classList.remove("block");
						languageListItemMobile.classList.add("hidden");
					}

					timeline.add({
						targets: "#menu",
						opacity: 0,
						complete: () => {
							clearAllBodyScrollLocks();
						},
					});

				} else {
					// open menu
					enableBodyScroll(header);
					menu.classList.add("open");
					hamburger.classList.add("close");
					header.classList.add("open-menu");
					body.classList.add("overflow-visible");
					body.classList.add("fixed");
					body.classList.add("left-0");
					body.classList.add("right-0");

					if (languageListItemMobile) {
						languageListItemMobile.classList.remove("open-menu-only");
					}

					// show menu-items when menu open
					menuItems.forEach(menuItem => {
						if (menuItem.dataset.lightswitchClosed == true) {
							menuItem.classList.remove("open-menu-only");
						}
					});
					// hide menu-items when menu open
					menuItems.forEach(menuItem => {
						if (menuItem.dataset.lightswitchOpen == true) {
							menuItem.classList.add("closed-menu-only");
						}
					});
					// UNCOMMENT NEXT LINE AND REMOVE THE LINE AFTER FOR LANGUAGE SWITCHER
					// logo.classList.add("hidden", "md:block");
					// logo.classList.remove("block");

					if (languageListItemMobile) {
						languageListItemMobile.classList.remove("hidden");
						languageListItemMobile.classList.add("block");
					}

					timeline.add({
						targets: "#menu",
						opacity: 1,
						complete: () => {
						},
					});
				}
			});
		}

		/// when menu open --> show and hide subbuttons (accordion)

		const menuItemDiv = document.getElementsByClassName("menu-item-div");

		if (menuItemDiv) {
			const menuItemDivClick = (eventHappened) => {
				let targetClicked = eventHappened.currentTarget;
				// element open
				const subbuttons = targetClicked.querySelector(".subbuttons");
				// rotate Icon
				const plusMinusToggle = targetClicked.querySelector(".plus-minus-toggle");

				if (!plusMinusToggle || !subbuttons) {
					return;
				}

				plusMinusToggle.classList.add("collapsed");

				if (subbuttons.style.maxHeight) {
					subbuttons.style.maxHeight = null;
				}


				// others
				// else {
				const allSubbuttons = document.getElementsByClassName("subbuttons");
				const allMenuItemDivs = document.getElementsByClassName("menu-item-div");
				for (let i = 0; i < allMenuItemDivs.length; i++) {
					const button = allMenuItemDivs[i].getElementsByClassName("plus-minus-toggle");
					if (button) {
						button.forEach(element => {
							element.classList.add("collapsed");
						});
					}
				}
				for (let i = 0; i < allSubbuttons.length; i++) {
					if (allSubbuttons[i].style.maxHeight) {
						allSubbuttons[i].style.maxHeight = null;
					}
				}
				//rotate Icon
				targetClicked.querySelector(".plus-minus-toggle").classList.remove("collapsed");
				subbuttons.style.maxHeight = subbuttons.scrollHeight + "px";
				// }
			}

			for (let i = 0; i < menuItemDiv.length; i++) {
				menuItemDiv[i].addEventListener("click", function (event) {
					menuItemDivClick(event);
				});
			}

		}
	},
}