import * as Klaro from "klaro/dist/klaro-no-css";

export default {
	init() {
		const config = {
			elementID: "cookie-banner",
			storageMethod: "cookie",
			storageName: "klaro-cookie",
			mustConsent: false, // Cookie banner is a Modal
			acceptAll: true,
			hideDeclineAll: false,
			translations: {
				de: {
					privacyPolicyUrl: "https://www.ara.at/datenschutz",
					consentNotice: {
						description: "Wir nutzen Cookies, um Ihnen bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu finden Sie hier: {privacyPolicy}",
						learnMore: "Einstellungen",
					},
					consentModal: {
						title: "Cookie Einstellungen",
						description: "Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen. ",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Datenschutzerklärung (Name)",
						},
					},
					ok: "Alle akzeptieren",
					decline: "Funktionale Cookies",
					acceptSelected: "Auswahl speichern",
					save: "Speichern",
					close: "Schließen",
					acceptAll: "Alle akzeptieren",
					purposes: {
						functional: "Funktionale Cookies",
						analytics: "Statistiken",
					},
				},
				en: {
					privacyPolicyUrl: "http://www.ara.at/en/data-protection-policy",
					consentNotice: {
						description: "We use cookies to offer you the best possible functionality. You can find more information here: {privacyPolicy}",
						learnMore: "Settings",
					},
					consentModal: {
						title: "Cookie settings",
						description: "We use cookies to offer you the best possible functionality. ",
						privacyPolicy: {
							text: "Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.",
							name: "Privacy Policy (Name)",
						},
					},
					ok: "Accept all",
					decline: "Functional cookies",
					acceptSelected: "Save selection",
					save: "Save",
					close: "Close",
					acceptAll: "Accept all",
					purposes: {
						functional: "Functional cookies",
						analytics: "Statistic",
					},
				},
			},
			services: [
				{
					name: "functional",
					title: "Functional cookies",
					default: true,
					purposes: ["functional"],
					cookies: [
						["CraftSessionId"],
						["*_identity"],
						["*_username"],
						["CRAFT_CSRF_TOKEN"],
					],
					required: true,
				},
				{
					name: "matomo",
					title: "Matomo Tracking",
					purposes: ["analytics"],
					required: false,
				},
			],
			callback: function (consent, service) {
				console.log(
					"User consent for service " + service.name + ": consent=" + consent
				);

				if (service.name === "matomo" && consent) {
					// Matomo Tracking Snippet
					let _paq = (window._paq = window._paq || []);
					/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
					_paq.push(["trackPageView"]);
					_paq.push(["enableLinkTracking"]);
					(function () {
						let u = "//matoma.agr.at/";
						_paq.push(["setTrackerUrl", u + "matomo.php"]);
						_paq.push(["setSiteId", "4"]);
						let d = document,
							g = d.createElement("script"),
							s = d.getElementsByTagName("script")[0];
						g.async = true;
						g.src = u + "matomo.js";
						s.parentNode.insertBefore(g, s);
					})();

				}
			},
		};

		// we assign the Klaro module to the window, so that we can access it in JS
		window.klaro = Klaro;
		window.klaroConfig = config;
		// we set up Klaro with the config
		Klaro.setup(config);
	},
};