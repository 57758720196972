
export default {
    init() {

        const cardImageGrid = document.getElementsByClassName("card-image-grid");
        if (!cardImageGrid) {
            return;
        }

        const cardId = window.location.hash.slice(1);
        const activeCard = document.getElementById(cardId);

        if (activeCard) {
            activeCard.classList.add("active-card");
        }
    },
}
