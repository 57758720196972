export default {
	init() {
		const loginSection = document.getElementsByClassName("login-section");
		const parentDiv = document.getElementById("content");

		if (parentDiv) {
			if (loginSection.length) {
				parentDiv.classList.remove("content");
				parentDiv.classList.remove("h-full");
			} else if (!parentDiv.classList.contains("content")) {
				parentDiv.classList.add("content");
				parentDiv.classList.add("h-full");
			}
		}

		const form = document.querySelector(".login-form");

		if (form) {
			const input = form.querySelectorAll("input");

			input.forEach((input) => {
				let inputParent = input.parentElement.previousElementSibling;

				input.addEventListener("focus", () => {
					inputParent.classList.add("float");
				});

				input.addEventListener("blur", () => {
					if (input.value.length == 0) {
						inputParent.classList.remove("float");
					}
				});
			})
		}
	},
}