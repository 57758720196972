export default {

	init() {
		const contentDiv = document.querySelector(".content");
        const footer = document.querySelector("footer");
        const header = document.querySelector("header");
		
        if (!contentDiv || !footer || !header) {
			return;
		}
        
        
        window.addEventListener("resize", () => {
            let footerHeight = footer.offsetHeight;
            let headerHeight = header.offsetHeight;
            const fullScreenHeight = window.innerHeight;
            
            if (!footerHeight || !headerHeight || !fullScreenHeight) {
                return;
            }
    
            contentDiv.style.minHeight = fullScreenHeight - (footerHeight + headerHeight) + "px";
        });

	},

};