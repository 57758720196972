import Swiper, { Pagination, Navigation } from "swiper";

let inizializedSwiper = [];

export default {

	init(swiperElement, swiperOptions) {

		Swiper.use([Pagination, Navigation]);
		inizializedSwiper.push(new Swiper(swiperElement, swiperOptions));
	},

	disable() {
		if (inizializedSwiper.length) {
			inizializedSwiper.forEach(function (element) {
				element.destroy();
			});
		} else {
			inizializedSwiper.destroy();
		}
	},
}
