export default {

	init() {
		//Get the button:
		const scrollToTopBtn = document.getElementById("scroll-to-top-button");
		let rootElement = document.documentElement;

		function scrollToTop() {
			// Scroll to top logic
			rootElement.scrollTo({
				top: 0,
				behavior: "smooth",
			})
		}

		scrollToTopBtn.addEventListener("click", scrollToTop);

	},
}
