export default {
    init() {
        const videoContainers = document.querySelectorAll(
            ".video-container"
        );

        if (!videoContainers.length) return;

        videoContainers.forEach(function (videoContainer) {
            // create Videos a bit after initial page load
            createVideo(videoContainer);

            const video = videoContainer.querySelector("#video");
            const playVideoButton = videoContainer.querySelector(".play-video");

            if(!video || !playVideoButton) return;

            const videoPoster = videoContainer.querySelector(
                ".video-fallback-image"
            );

            // //show video play button if video is played
            video.addEventListener("play", () => {
                playVideoButton.classList.remove("opacity-100");
                playVideoButton.classList.add("opacity-0");

                if(videoPoster) {
                    videoPoster.classList.remove("opacity-100");
                    videoPoster.classList.add("opacity-0");
                }
            });

            // //show video play button if video is played
            video.addEventListener("pause", () => {
                playVideoButton.classList.remove("opacity-0");
                playVideoButton.classList.add("opacity-100");

                if(videoPoster) {
                    videoPoster.classList.remove("opacity-0");
                    videoPoster.classList.add("opacity-100");
                }
            });

            addEventListener("canplaythrough", () => {
                playVideoButton.classList.add("opacity-100");
                playVideoButton.classList.remove("opacity-0");

                if(videoPoster) {
                    videoPoster.classList.add("opacity-100");
                    videoPoster.classList.remove("opacity-0");
                }
            });

            //show video poster if is in loading state
            video.addEventListener("waiting", () => {
                playVideoButton.classList.remove("opacity-100");
                playVideoButton.classList.add("opacity-0");

                if(videoPoster) {
                    videoPoster.classList.remove("opacity-0");
                    videoPoster.classList.add("opacity-100");
                }
            });

            //show video poster if is not loaded
            video.addEventListener("error", () => {
                playVideoButton.classList.remove("opacity-100");
                playVideoButton.classList.add("opacity-0");
                
                if(videoPoster) {
                    videoPoster.classList.remove("opacity-0");
                    videoPoster.classList.add("opacity-100");
                }
            });

            /**
             * Create video element
             */
            function createVideo(videoContainer) {
                const newVideoElement = videoContainer.querySelector(
                    "#video"
                );

                if (!newVideoElement) return;

                if (newVideoElement) {
                    if (
                        newVideoElement.dataset.video &&
                        newVideoElement.dataset.poster &&
                        newVideoElement.dataset.video !==
                            newVideoElement.getAttribute("src")
                    ) {
                        newVideoElement.setAttribute(
                            "poster",
                            newVideoElement.dataset.poster
                        );
                        newVideoElement.setAttribute(
                            "src",
                            newVideoElement.dataset.video
                        );
                        newVideoElement.setAttribute("preload", "auto");

                        newVideoElement.pause();
                    }
                }
            }
        });
    },
};
