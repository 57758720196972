import lottie from "lottie-web";
import anime from "animejs";
import ScrollMagic from "scrollmagic";

export default {
    init(section) {
        const controller = new ScrollMagic.Controller();
        const timeline = anime.timeline();
        const iconScene = new ScrollMagic.Scene({
            triggerElement: section,
            triggerHook: 1,
        }).addTo(controller);
        let animationPlayed = false;
        if (!iconScene) return;

        // starting first lottie animation - play once when scene enters viewport
        const animationContainers = document.querySelectorAll(".lottie-player");
        if (!animationContainers) return;

        animationContainers.forEach(animationContainer => {
            const animationUrl = animationContainer.dataset.animationUrl;
            if (!animationUrl) return;

            iconScene.on("enter", function () {
                if (!animationPlayed) {
                    timeline.add({
                        begin: () => {
                            animationPlayed = true;
                        },
                    });
                    const lottieAnimation = lottie.loadAnimation({
                        container: animationContainer,
                        autoplay: true,
                        path: animationUrl,
                        loop: false,
                        rendererSettings: {
                            className: "w-auto",
                        },
                    });

                    if (!lottieAnimation) {
                        return;
                    }
                }
            });
        });
        
        // // starting hover animations - loop
        const hoverAnimationContainers = document.querySelectorAll(".lottie-player-hover");
        if (!hoverAnimationContainers) return;

        hoverAnimationContainers.forEach(hoverAnimationContainer => {
            const hoverAnimationUrl = hoverAnimationContainer.dataset.animationUrl;
            if (!hoverAnimationUrl) return;

            const lottieHoverAnimation = lottie.loadAnimation({
                container: hoverAnimationContainer,
                autoplay: true,
                path: hoverAnimationUrl,
                loop: true,
                rendererSettings: {
                    className: "w-auto",
                },
            });

            if (!lottieHoverAnimation) {
                return;
            }
        });
    },
};
