
export default {
    init() {

		const elements = document.querySelectorAll("a[href^=\"#\"]");

		if (!elements) {
			return;
		}


		elements.forEach(element => {
			element.onclick = function(e) {
				e.preventDefault();
				let hash = this.getAttribute("href");
				if (hash.length <= 1) {
					return
				} 
				let target = document.querySelector(hash);
				if (!target) {
					return;
				}
				let headerOffset = 150;
				let elementPosition = target.offsetTop;
				let offsetPosition = elementPosition + headerOffset;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			};
		});
	},
}

