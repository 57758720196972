import "intersection-observer";
import swiper from "./swiper";
import contactForm from "./contact-form";
import imageGallery from "./image-gallery";
import accordion from "./accordion";
import accordionTab from "./accordion-tabs";
import footer from "./footer";
import lazyload from "./dal-lazyload";
import AOS from "aos";
import "lazysizes";
import navigation from "./navigation";
import jumpmark from "./jumpmark";
import cardGrid from "./card-grid";
import filterButtons from "./filter-buttons";
import newsFilter from "./news-filter";
import search from "./search";
import cookieBanner from "./cookie-banner";
import contentHeight from "./content-height";
import loginForm from "./login-form";
import iconAnimation from "./icon-animation";
import iframeRecyclingGuide from "./iframe-recycling-guide";
import anniversary from "./anniversary";
import video from "./video";
import contest from "./contest";

// eslint-disable-next-line no-undef
require("./autoload"); //enforce to copy all images of src into web folder

(function () {
	//// All Swiper
	let allSwiper = document.querySelectorAll(".swiper-container");
	if (allSwiper.length) {
		allSwiper.forEach(function (swiperEle) {
			//Set swiper element and swiper options from data-attribute
			swiper.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
		});
	}

	contactForm.init();

	contentHeight.init();

	imageGallery.init();

	
	const iconTitleSections = document.querySelectorAll(
        ".icon-title-section"
    );
    if (iconTitleSections.length) {
        iconTitleSections.forEach((section) => {
            iconAnimation.init(section);
        });
    }

	accordion.init();

	accordionTab.init();

	footer.init();

	lazyload.init();

	navigation.init();

	jumpmark.init();

	cardGrid.init();

	loginForm.init();

	anniversary.init();

	iframeRecyclingGuide.init();

	video.init();
	
	contest.init();

	// eslint-disable-next-line no-undef
	htmx.on("htmx:afterSwap", function () {
		// recreate aos after sprig reloads something
		AOS.init();
		newsFilter.init();
		search.init();

		//// All Swiper
		let allSwiper = document.querySelectorAll(".swiper-container");
		if (allSwiper.length) {
			allSwiper.forEach(function (swiperEle) {
				//Set swiper element and swiper options from data-attribute
				swiper.init(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
			});
		}
	});

	AOS.init();

	newsFilter.init();

	search.init();

	filterButtons.init();

	// remove comment if you want to add a cookie banner
	cookieBanner.init();
})();
