export default {
    init() {
        const iframeRecyclingGuide = document.querySelector(".iframe-div iframe");
        if (!iframeRecyclingGuide) return;
        
        const contentDiv = document.querySelector(".content");
        const footer = document.querySelector("footer");
        const header = document.querySelector("header");

        if (!contentDiv || !footer || !header) {
            return;
        }

        let iframeHeight;
        
        window.addEventListener("message", (message) => {
            let footerHeight = footer.offsetHeight;
            let headerHeight = header.offsetHeight;
            const fullScreenHeight = window.innerHeight;
            
            if (!footerHeight || !headerHeight || !fullScreenHeight) {
                return;
            }
            if (message && message.data && message.data.name === "contentSizeChanged") {
                iframeRecyclingGuide.style.height = message.data.height + "px";
                iframeHeight = message.data.height;

                let currentAvailableHeight = fullScreenHeight - footerHeight - headerHeight;
                if (!currentAvailableHeight) {
                    return;
                }

                // set iframe min-height to available height when content is smaller than available height
                if (iframeHeight < currentAvailableHeight) {
                    iframeRecyclingGuide.style.minHeight = currentAvailableHeight + "px";
                } else {
                    iframeRecyclingGuide.style.minHeight = "auto";
                }
            }
        });
       
        window.addEventListener("resize", () => {
            let footerHeight = footer.offsetHeight;
            let headerHeight = header.offsetHeight;
            const fullScreenHeight = window.innerHeight;
            
            if (!footerHeight || !headerHeight || !fullScreenHeight) {
                return;
            }
            
            let currentAvailableHeight = fullScreenHeight - footerHeight - headerHeight;
            if (!currentAvailableHeight) {
                return;
            }

            // set iframe min-height to available height when content is smaller than available height
            if (iframeHeight < currentAvailableHeight) {
                iframeRecyclingGuide.style.minHeight = currentAvailableHeight + "px";
            } else {
                iframeRecyclingGuide.style.minHeight = "auto";
            }
        });
    },
};
