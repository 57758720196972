import Swiper from "swiper";
let filterButtonsSwiper = [];

export default {
	init() {
		let allFilterButtonSwiper = document.querySelectorAll(".filter-button-container");
		if (allFilterButtonSwiper.length) {
			allFilterButtonSwiper.forEach(function (swiperEle) {
				//Set swiper element and swiper options from data-attribute
				initFilterButtons(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
			})
		}

		//eslint-disable-next-line no-undef
		htmx.on("htmx:afterSwap", function (event) {
			if (event.target.id == "news-filter-sprig") {
				console.log("refresh");
				let allFilterButtonSwiper = document.querySelectorAll(".filter-button-container");
				if (allFilterButtonSwiper.length) {
					allFilterButtonSwiper.forEach(function (swiperEle) {
						//Set swiper element and swiper options from data-attribute
						initFilterButtons(swiperEle, JSON.parse(swiperEle.dataset.swiperOptions));
					})
				}
			}
		});

		function initFilterButtons(swiperElement, swiperOptions) {
			filterButtonsSwiper.push(new Swiper(swiperElement, swiperOptions));

			let buttonSlides = document.querySelectorAll(".filter-button-container .swiper-slide");

			if (buttonSlides.length > 0) {
				buttonSlides.forEach((slide) => {
					if (slide.classList.contains("active")) {
						let index = slide.dataset.index;

						filterButtonsSwiper.forEach((swiper) => {
							let swiperDomElem = swiper.$el[0];
							swiperDomElem.style.opacity = 0;
							swiper.slideTo(index, 0);
							swiperDomElem.style.opacity = 1;
						})
					}
				})
			}
		}
	},
}
