export default {

	init() {
		const imageGallery = document.querySelectorAll(".image-gallery");

		for (let i = 0; i < imageGallery.length; i++) {

			const swiperSlide = imageGallery[i].getElementsByClassName("swiper-slide-gallery");
			const closeIcon = imageGallery[i].querySelector(".close-icon-div");
			const galleryLightbox = imageGallery[i].querySelector(".gallery-lightbox");

			if (swiperSlide) {

				for (let i = 0; i < swiperSlide.length; i++) {
					swiperSlide[i].addEventListener("click", () => {
						galleryLightbox.classList.remove("opacity-0");
						galleryLightbox.classList.remove("pointer-events-none");
						galleryLightbox.querySelector(".swiper-container").swiper.slideTo(i, 0);
						document.body.classList.add("overflow-hidden");
					});
				}

				if (closeIcon) {
					closeIcon.addEventListener("click", () => {
						galleryLightbox.classList.add("opacity-0");
						galleryLightbox.classList.add("pointer-events-none");
						document.body.classList.remove("overflow-hidden");
					})
				}
			}
		}
	},
};