export default {

	init() {
		const anniversarySections = document.querySelectorAll(".anniversary-section");
		if (!anniversarySections) return;

		anniversarySections.forEach(function (section) {

            const cards = section.querySelectorAll(".cards");

            if(!cards) return;

            cards.forEach(function (card) {
                const moreTextContainer= card.querySelector(".more-text-container");

                if(!moreTextContainer) return;

                const textContainer= moreTextContainer.querySelector(".text-container");
                const toggleButton = moreTextContainer.querySelector(".toggle-button");
    
                if(!toggleButton || !textContainer) {
                    return;
                }

                const textContainerHeight = textContainer.scrollHeight;
                let isOpen = false;
                
                toggleButton.addEventListener("click", function () {
                    isOpen = !isOpen;

                    if(isOpen) {
                        moreTextContainer.classList.add("open");
                        textContainer.style.maxHeight = textContainerHeight + "px";
                    }else {
                        moreTextContainer.classList.remove("open");
                        textContainer.style.maxHeight = 0 + "px";
                    }
                });
            });
		})
	},
};