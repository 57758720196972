export default {
	init() {
		const newsPosts = document.querySelectorAll(".news-results .news-post");
		const lg = window.matchMedia("(min-width: 1024px)");
	
		if (newsPosts) {
			newsPosts.forEach((news) => {
				let headline = news.querySelector(".text h3");
				let headlineHeight = headline.offsetHeight;
				let lines = headlineHeight / 36; // divide headline height and line height of headline in px
				let overlay = news.querySelector(".overlay");
			
				if (lines > 2) {
					headline.classList.add("cut-text");
				}

				if (overlay) {
					if (lines > 6) {
						overlay.classList.add("hide");
					} else if (lines > 3) {
						overlay.classList.add("cut-overlay-5");
					} else {
						overlay.classList.add("cut-overlay-8");
					}
				}

				if (lg.matches) {
					news.addEventListener("mouseover", () => {
						headline.classList.remove("cut-text");
					},false)
	
					news.addEventListener("mouseleave", () => {
						headline.classList.add("cut-text");
					},false)
				}
				
			})
		}

	},
}